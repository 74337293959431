/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { BASE_URL } from 'config';
import { Banner } from 'components';

const DeclaresClimateEmergency = () => (
  <Layout page="haka-tours-declares-climate-emergency">

    <Helmet
      title="Haka Tours Declares Climate Emergency"
      meta={[
        {
          name: 'description', content: 'Haka Tours have signed up to Tourism Declares, an initiative that supports tourism businesses, organisations and individuals in declaring a climate emergency and taking purposeful action to reduce our carbon emissions.',
        },
        { name: 'og:title', content: 'Haka Tours Declares Climate Emergency' },
        {
          name: 'og:description', content: 'Haka Tours have signed up to Tourism Declares, an initiative that supports tourism businesses, organisations and individuals in declaring a climate emergency and taking purposeful action to reduce our carbon emissions.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/haka-tours-declares-climate-emergency/` }]}
    />
    <Banner backgroundImage="https://static-hakatours.imgix.net/HakaTours-Declares-Climate-Emergency-Dolphins+Kaikoura.jpg?fit=crop&auto=format" />
    <section className="l-section">
      <div className="l-container">
        <h1 className="l-contact-us__title c-heading c-heading--h1 u-text--center">Haka Tours Declares a Climate Emergency</h1>
        <br />
        <p>
          Haka Tours have signed up to <a href="http://www.tourismdeclares.com/">Tourism Declares</a>, an initiative that supports tourism businesses, 
          organisations and individuals in declaring a climate emergency and taking purposeful action to reduce our carbon emissions as per the advice 
          from <a href="https://www.ipcc.ch/">The Intergovernmental Panel on Climate Change (IPCC)</a> to cut global carbon emissions to 55% below 2017 levels by 2030.
        </p>
        <br />
        <h3 className="l-contact-us__title c-heading c-heading--h3">Here’s why</h3>
        <p>
          In 2007, Haka Tours started life as a tour operator, whose purpose was to foster deeper connections between our manuhiri (guests), our land and our culture, 
          through exceptional travel experiences. Which still rings true today.
        </p>
        <p>
          New Zealand is an isolated nation, in the middle of the Pacific Ocean and as such, we can all physically see, first-hand, 
          the devastating effects that climate change is having on our country and communities. Rising snow lines, increasing extreme rainfall, 
          biodiversity issues and glacial melts are just four of many issues that face our beautiful nation. At Haka Tours, we want to be part of the change, 
          for the regeneration of Aotearoa for generations to come.
        </p>
        <p>
          It has now become the responsibility of individuals and businesses, whether small or large, to take rapid and radical action against climate change.
          As our pledge to ‘Tourism Declares’, over the coming three years, we will be looking to significantly reduce our impact on the environment and we will do this by:
        </p>
        <p>
          <ul>
            <li>Creating a ‘Climate Action Plan’</li>
            <li>Committing to local biodiversity efforts</li>
            <li>Partnering with local organisations to provide much needed funding for environmental projects</li>
            <li>Focussing on our internal processes to ensure we are environmentally focussed</li>
            <li>Providing our manuhiri with an environmentally unencumbered and magical experience of Aotearoa, New Zealand</li>
          </ul>
        </p>
        <p>
          Like all signatories, we have committed to the following five actions:
        </p>
        <p>
          1. Developing a ‘Climate Emergency Plan’ within the next 12 months, which sets out our intentions to reduce carbon emissions over the next decade.
        </p>
        <p>
          2. Sharing an initial public declaration of our ‘Climate Emergency Plan’, and update on progress each year.
        </p>
        <p>
          3. Accepting current IPCC advice stating the need to cut global carbon emissions to 55% below 2017 levels by 2030 in order to keep the planet within 1.5 degrees of warming.
          We’ll ensure our ‘Climate Emergency Plan’ represents actions designed to achieve this as a minimum, through delivering transparent, 
          measurable and increasing reductions in the total carbon emissions per customer arising from our operations and the travel services sold by us. 
        </p>
        <p>
          4. Encouraging our suppliers and partners to make the same declaration; sharing best practice amongst peers; and actively participate in the Tourism Declares community
        </p>
        <p>
          5. Advocating for change. We recognise the need for system change across the industry to accelerate a just transition towards carbon-free tourism.
        </p>
        <p>
          We look forward to being a part of this initiative and assisting tourism to be as regenerative as possible for our own and future generations.
        </p>
      </div>
    </section>
  </Layout>
);

export default DeclaresClimateEmergency;